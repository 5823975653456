<template>
  <div class="header">
    <h1>NFT</h1>
    <ul class="path">
      <li><router-link to="/mypage">My Page</router-link></li>
      <li class="current">
        <router-link to="/mypage/nft">NFT</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MyNFT",
  data: function () {
    return {};
  },
  mounted() {
    this.init_data();
  },
  methods: {
    init_data: function () {
      let self = this;
      this.$swal
        .fire({
          text: this.$t("common.under_construction"),
          icon: "info",
        })
        .then(() => {
          self.$router.back();
        });
      return;
    },
  },
};
</script>

<style scoped></style>
